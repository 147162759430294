<!--eslint-disable-->
<template>
  <div class="owners-page">

    <div class="row">
      <div class="col-12">
        <div v-if="$can('Read', 'Owner')">
          <b-card-actions action-refresh action-collapse no-body @refresh="getMoreActivities(1)" ref="refreshCard" class="mb-0" title="Owner Device Activities">
            <b-card-body>
              <b-card-sub-title>These activities show the actions logged from the apps on the device</b-card-sub-title>


            </b-card-body>
            <div class="m-2">
              <b-row>
                <!-- Search -->
                <b-col cols="12" md="12">
                  <div class="d-flex align-items-center justify-content-end">

                    <b-input-group class="mr-1">
                      <b-form-input v-model="filters.search" class="d-inline-block"
                                    v-on:keydown.enter="getActivities" placeholder="Search..."/>
                      <b-input-group-append>
                        <b-button v-if="filters.search" @click="clearSearch" variant="warning">Clear</b-button>
                        <b-button @click="getActivities" variant="primary">Search</b-button>
                      </b-input-group-append>
                    </b-input-group>

                    <v-select v-model="filters.app"
                              :reduce="app => app.code"
                              :options="appOptions" class="invoice-filter-select"
                              placeholder="Filter by App" @input="getActivities">

                      <template #selected-option="{ label }">
                        <span class="text-truncate overflow-hidden">
                          {{ label }}
                        </span>
                      </template>
                    </v-select>
                  </div>
                </b-col>
              </b-row>
            </div>

            <section>
              <b-table
                class="data-table"
                hover
                :items="activities"
                :fields="headers"
                :current-page="currentPage"
                :per-page="0"
                show-empty
                :responsive="true"
              >
                <template #cell(owner_uid)="data">
                  <router-link :to="{ name: 'owner-view', params: { uid: data.item.owner_uid } }" class="font-weight-bold d-block text-nowrap">
                    {{ data.item.owner ? data.item.owner.identifier : 'View User' }}
                  </router-link>
                </template>

                <template #cell(description)="data">
                  <span v-if="data.item.action === 'BlockedRequest'">
                    Blocked request to: <a :href="getBlockedRequestUrl(data.item.description)" target="_blank">{{getBlockedRequestUrl(data.item.description)}}</a>
                  </span>
                  <span v-else>
                    {{data.item.description}}
                  </span>
                </template>

                <template #cell(created_at)="data">
                  {{ data.item.created_at | formatDateTime}}
                </template>

              </b-table>
              <b-row>
                <b-col md="6" class="my-1">
                  <b-pagination v-if="total_count > filters.page_size" @change="getMoreActivities" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
                </b-col>
              </b-row>
            </section>
          </b-card-actions>
        </div>
      </div>
    </div>


  </div>
</template>
<!--eslint-enable-->

<script>
  import vSelect from 'vue-select'
  import OwnersService from '@/services/OwnersService';
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
  import ApplicationsService from "@/services/ApplicationsService";

  export default {
    name: 'OwnerActivities',
    components: {
      BCardActions,
      vSelect,
    },
    data() {
      return {
        loading: true,
        activities: [],
        appOptions: [],
        headers: [
          {
            key: 'app_name',
            label: 'App',
          },
          {
            key: 'action',
            label: 'Action',
          },
          {
            key: 'description',
            label: 'Description',
          },
          {
            key: 'created_at',
            label: 'Created At',
          },
          {
            key: 'owner_uid',
            label: 'Owner',
          },
        ],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 12,
          search: '',
          app: '',
        },
        total_count: 0,
      };
    },
    mounted() {
      this.getApplications();
      this.getActivities();
    },
    methods: {
      async getApplications() {
        const appRequest = await ApplicationsService.getApplications();
        if (appRequest.data) {
          appRequest.data.forEach(item => {
            this.appOptions.push({
              label: item.name,
              code: item.package_name,
            })
          })
        }
      },
      getMoreActivities(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getActivities();
      },
      getActivities() {
        this.$refs.refreshCard.showLoading = true
        OwnersService.getActivities(this.filters).then(res => {
          this.activities = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get owner activities, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        }).finally(() => {
          setTimeout(() => {
            this.$refs.refreshCard.showLoading = false
          }, 100)
        });
      },
      clearSearch() {
        this.filters.search = '';
        this.getActivities()
      },
      getBlockedRequestUrl(desc) {
        return desc.replace('Blocked request to url: ', '')
      },
    },
  };
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 300px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 200px;
  }
}
</style>
